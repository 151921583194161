import RequestUtils from "../utils/RequestUtils";
import LoginDriverReq from "../types/req/driver/LoginDriverReq";
import LoginRes from "../types/res/driver/LoginRes";
import DriverRes from "types/res/driver/DriverRes";

export default class DriverApi {
  static async login(data: LoginDriverReq): Promise<LoginRes> {
    return await RequestUtils.post(LoginRes, `/auth/login`, data);
  }

  static async logout() {
    return await RequestUtils.postVoid(`/auth/logout`, undefined);
  }

  static async findByResetToken(resetToken: string) {
    return await RequestUtils.get(
      DriverRes,
      `/driver/findByResetToken?token=${resetToken}`
    );
  }

  static async resetPassword(email: string, password: string) {
    return await RequestUtils.post(DriverRes, `/driver/passwordReset`, {
      email,
      password,
    });
  }

  static async resetPasswordSendEmail(email: string) {
    return await RequestUtils.postVoid(`/driver/passwordReset/sendEmail`, {
      email,
    });
  }

  static async findByEmail(email: string) {
    return await RequestUtils.postArray(DriverRes, `/driver/findByEmail`, {
      email,
    });
  }
}
