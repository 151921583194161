import { createSlice } from "@reduxjs/toolkit";

import {
  deleteShift,
  fetchShifts,
  postDriverStatus,
  postShift,
  putShift,
} from "redux/actions/shifts";
import ShiftRes from "types/res/shift/ShiftRes";
import CompanyRes from "types/res/company/CompanyRes";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";

export type ShiftState = { [date: string]: ShiftRes };

const initialState = {} as ShiftState;

export const configureKey = (start: Date, company: CompanyRes) => {
  const changeDate = company.changeDateTime
    ? DateTime.fromFormat(company.changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");
  return DateTime.fromJSDate(start)
    .minus({ hour: changeDate.hour })
    .toFormat(FORMAT_TYPE.YEAR_DAY);
};

const configureShifts = (raw: ShiftRes[]): ShiftState => {
  const result: ShiftState = {};
  if (!raw) return result;
  raw.forEach((shift) => {
    const key = configureKey(shift.planWorkStart, shift.company);
    result[key] = shift;
  });
  return result;
};

const shiftsSlice = createSlice({
  name: "shifts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShifts.fulfilled, (_, { payload }) =>
      configureShifts(payload)
    );
    builder.addCase(postShift.fulfilled, (state, { payload }) => {
      const key = configureKey(payload.planWorkStart, payload.company);
      return { ...state, [key]: payload };
    });
    builder.addCase(putShift.fulfilled, (state, { payload }) => {
      const key = configureKey(payload.planWorkStart, payload.company);
      return { ...state, [key]: payload };
    });
    builder.addCase(postDriverStatus.fulfilled, (state, { payload }) => {
      const key = configureKey(payload.planWorkStart, payload.company);
      return { ...state, [key]: payload };
    });
    builder.addCase(deleteShift.fulfilled, (state, { payload }) => {
      const deleteShift = Object.values(state).find(
        (castShift) => castShift.driverShiftId === payload.driverShiftId
      );
      if (!deleteShift) return { ...state };
      const key = configureKey(deleteShift.planWorkStart, deleteShift.company);
      const { [key]: _removed, ...res } = state;
      return res;
    });
  },
});

export default shiftsSlice.reducer;
