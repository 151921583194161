import React from "react";
import styled from "styled-components";
import DateTimeUtils from "utils/DateTimeUtils";
import { Button, TextField } from "@material-ui/core";
import { ArrowBack, ArrowForward, CheckCircle } from "@material-ui/icons";

import ShiftStatus from "types/enum/ShiftStatus";
import EnumUtils from "../../utils/EnumUtils";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  height: 100%;
  > * {
    margin-bottom: 24px;
  }
`;

export const DateTimeContainer = styled.div`
  text-align: center;
  font-size: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;

const DateChangerContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
`;

const DateChangerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerifiedContainer = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ShiftContent = ({
  message,
  onApproveShift,
  companyTel,
  onChangeMessage,
  onClickSendMessage,
  shift,
}: any) => {
  const verified =
    ShiftStatus.verified === EnumUtils.mapToEnum(ShiftStatus, shift?.status);
  const changeDateTime = useSelector(
    (state) => state.account.driver.company.changeDateTime
  );
  const changeDate = changeDateTime
    ? DateTime.fromFormat(changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");
  if (!shift) return <div>シフトがありません</div>;

  return (
    <>
      <DateTimeContainer>
        {0 <= shift.planWorkStart.getHours() &&
        shift.planWorkStart.getHours() <= changeDate.hour
          ? shift.planWorkStart.getHours() + 24
          : shift.planWorkStart.getHours()}
        : {String(shift.planWorkStart.getMinutes()).padStart(2, "0")} -
        {0 <= shift.planWorkEnd.getHours() &&
        shift.planWorkEnd.getHours() <= changeDate.hour
          ? shift.planWorkEnd.getHours() + 24
          : shift.planWorkEnd.getHours()}
        : {String(shift.planWorkEnd.getMinutes()).padStart(2, "0")}{" "}
      </DateTimeContainer>
      {verified && (
        <VerifiedContainer>
          <CheckCircle style={{ color: "green" }} /> 確認済み
        </VerifiedContainer>
      )}
      <ButtonsContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={onApproveShift}
          disabled={verified}
        >
          予定通り出勤
        </Button>
        {/* TODO: Make a call */}
        <Button variant="contained" color="primary" href={`tel:${companyTel}`}>
          遅刻/お休みの連絡　☎
        </Button>
      </ButtonsContainer>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        rows={5}
        value={message}
        onChange={onChangeMessage}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onClickSendMessage}
        disabled={!message}
      >
        メッセージ送信
      </Button>
    </>
  );
};

export const DateChanger = ({ onClickPreviousDay, onClickNextDay }: any) => {
  return (
    <DateChangerContainer>
      <DateChangerButtonContainer>
        <Button variant="contained" onClick={onClickPreviousDay}>
          <ArrowBack />
        </Button>
        <div>前日</div>
      </DateChangerButtonContainer>
      <DateChangerButtonContainer>
        <Button variant="contained" onClick={onClickNextDay}>
          <ArrowForward />
        </Button>
        <div>翌日</div>
      </DateChangerButtonContainer>
    </DateChangerContainer>
  );
};
