import { DataValidationError } from "constants/DataValidationError";
import { IsAscii, IsEmail, IsNotEmpty, MaxLength } from "class-validator";
import Spec from "constants/Spec";

export default class LoginDriverReq {
  @MaxLength(Spec.maxLength.driver.email, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.email,
    },
  })
  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  @IsEmail(
    {},
    {
      message: DataValidationError.DATA_IS_INVALID,
    }
  )
  email!: string;

  @IsNotEmpty({
    message: DataValidationError.DATA_IS_EMPTY,
  })
  @MaxLength(Spec.maxLength.driver.password, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.driver.password,
    },
  })
  @IsAscii({
    message: DataValidationError.DATA_IS_INVALID,
  })
  password!: string;

  constructor(arg: { email: string; password: string }) {
    arg = arg || {};
    this.email = arg.email;
    this.password = arg.password;
  }
}
