import { Expose, Type } from "class-transformer";
import GuestCategoryRes from "../guestCategory/GuestCategoryRes";
import GuestPointRes from "../guestPoint/GuestPointRes";

export default class GuestRes {
  @Expose() readonly guestId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly name!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly address!: string;
  @Expose() readonly memo!: string;
  @Type(() => GuestCategoryRes)
  @Expose()
  readonly guestCategory!: GuestCategoryRes;
  @Expose()
  readonly guestCategoryId!: number;
  @Type(() => GuestPointRes)
  @Expose()
  readonly guestPoints!: GuestPointRes[];
}
