const Spec = {
  maxLength: {
    driver: {
      email: 256,
      name: 128,
      password: 32,
      address: 1024,
    },
    driverShift: {
      staffMemo: 1024,
      driverMemo: 1024,
      roadConditionMemo: 1024,
    },
  },
};
export default Spec;
