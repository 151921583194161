import { changeAccount, logout, signin } from "redux/actions/account";
import LoginRes from "types/res/driver/LoginRes";
import { createSlice } from "@reduxjs/toolkit";

export type AccountState = LoginRes;

const initialState = {} as AccountState;

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signin.fulfilled, (_, { payload }) => payload);
    builder.addCase(logout.fulfilled, () => ({} as AccountState));
    builder.addCase(changeAccount.fulfilled, (state, { payload }) => ({
      ...state,
      driver: payload,
    }));
  },
});

export default accountSlice.reducer;
