import { combineReducers } from "redux";
import modals from "./modals";
import account from "./account";
import shifts from "./shifts";
import toast from "./toast";
import orders from "./orders";
import notifications from "./notifications";
import payoff from "./payoff";
import "react-redux";
import company from "redux/reducers/company";
import shops from "redux/reducers/shops";
import roadConditions from "redux/reducers/roadConditions";

export type AppState = ReturnType<typeof reducer>;

declare module "react-redux" {
  interface DefaultRootState extends AppState {}
}

const reducer = combineReducers({
  modals,
  account,
  company,
  shops,
  shifts,
  orders,
  toast,
  notifications,
  payoff,
  roadConditions,
});

export default reducer;
