import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import GuestRes from "../guest/GuestRes";
import GuestPointRes from "../guestPoint/GuestPointRes";
import CastNameRes from "../castName/CastNameRes";
import CourseRes from "../course/CourseRes";
import AdditionalTimeRes from "../additionalTime/AdditionalTimeRes";
import DiscountRes from "../discount/DiscountRes";
import OptionRes from "../option/OptionRes";
import HotelRes from "../hotel/HotelRes";
import DriverRes from "../driver/DriverRes";
import MediumRes from "../medium/MediumRes";
import OrderStatus from "types/enum/OrderStatus";
import OrderResponseStatus from "types/enum/OrderResponseStatus";
import PaymentType from "types/enum/PaymentType";
import CosplayRes from "../cosplay/CosplayRes";
import StaffRes from "../staff/StaffRes";
import AreaRes from "../area/AreaRes";
import NominationRes from "../nomination/NominationRes";
import NotelClassRes from "../notelClass/NotelClassRes";

export default class OrderRes {
  @Expose() readonly orderId!: number;
  @Expose() readonly companyId!: number;

  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;
  @Expose() readonly guestId!: number | null;

  @Type(() => GuestPointRes)
  @Expose()
  readonly guestPoints!: GuestPointRes[];

  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
  @Expose() readonly shopId!: number | null;

  @Type(() => CastNameRes)
  @Expose()
  readonly castName!: CastNameRes;
  @Expose() readonly castNameId!: number | null;

  @Type(() => CourseRes)
  @Expose()
  readonly course!: CourseRes;
  @Expose() readonly courseId!: number | null;

  @Type(() => NominationRes)
  @Expose()
  readonly nomination!: NominationRes;
  @Expose()
  readonly nominationId!: number | null;
  @Type(() => NotelClassRes)
  @Expose()
  readonly notelClass!: NotelClassRes;
  @Expose()
  readonly notelClassId!: number | null;

  @Type(() => AdditionalTimeRes)
  @Expose()
  readonly additionalTime!: AdditionalTimeRes;
  @Expose() readonly additionalTimeId!: number | null;

  @Type(() => DiscountRes)
  @Expose()
  readonly discounts!: DiscountRes[];

  @Type(() => OptionRes)
  @Expose()
  readonly options!: OptionRes[];

  @Type(() => AreaRes)
  @Expose()
  readonly area!: AreaRes;
  @Expose() readonly areaId!: number | null;

  @Type(() => HotelRes)
  @Expose()
  readonly hotel!: HotelRes;
  @Expose() readonly hotelId!: number | null;

  @Type(() => CosplayRes)
  @Expose()
  readonly cosplay!: CosplayRes;
  @Expose() readonly cosplayId!: number | null;

  @Type(() => DriverRes)
  @Expose()
  readonly outwardDriver!: DriverRes;
  @Expose() readonly outwardDriverId!: number | null;

  @Type(() => DriverRes)
  @Expose()
  readonly returnDriver!: DriverRes;
  @Expose() readonly returnDriverId!: number | null;

  @Type(() => MediumRes)
  @Expose()
  readonly medium!: MediumRes;
  @Expose() readonly mediumId!: number | null;

  @Expose() readonly status!: OrderStatus;

  @Type(() => Date)
  @Expose()
  readonly orderDate!: Date;

  @Expose() readonly totalTime!: number | null;

  @Type(() => Date)
  @Expose()
  readonly departureTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly pickUpTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly planInTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly planOutTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly actualInTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly actualEndTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly requestActualInTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly requestActualEndTime!: Date | null;

  @Type(() => Date)
  @Expose()
  readonly returnTime!: Date | null;

  @Expose()
  readonly responseStatus!: OrderResponseStatus | null;

  @Expose()
  readonly collectReceivable!: boolean;

  @Expose()
  readonly payoff!: boolean;

  @Expose() readonly additionalTimeCount!: number | null;
  @Expose() readonly paymentType!: PaymentType | null;
  @Expose() readonly memo!: string | null;
  @Expose() readonly questionnaire!: string | null;
  @Expose() readonly score!: number | null;
  @Expose() readonly orderAddress!: string | null;
  @Expose() readonly isSendNewBookEmailOutwardDriver!: boolean;
  @Expose()
  readonly isSendChangeBookEmailOutwardDriver!: boolean;
  @Expose() readonly isSendNewBookEmailReturnDriver!: boolean;
  @Expose() readonly isSendChangeBookEmailReturnDriver!: boolean;
  @Expose() readonly isSendNewBookEmailCast!: boolean;
  @Expose() readonly isSendChangeBookEmailCast!: boolean;
  @Expose() readonly bookEmailBody!: string;
  @Expose() readonly courseTime!: number | null;
  @Expose() readonly returnDuration!: number | null;
  @Expose() readonly outwardDuration!: number | null;
  @Expose() readonly courseFee!: number | null;
  @Expose() readonly courseCastFee!: number | null;
  @Expose() readonly courseShopFee!: number | null;
  @Expose() readonly timeFee!: number | null;
  @Expose() readonly timeCastFee!: number | null;
  @Expose() readonly timeShopFee!: number | null;
  @Expose() readonly welfareFee!: number | null;
  @Expose() readonly hotelFee!: number | null;
  @Expose() readonly hotelCastFee!: number | null;
  @Expose() readonly hotelShopFee!: number | null;
  @Expose() readonly designateFee!: number | null;
  @Expose() readonly designateCastFee!: number | null;
  @Expose() readonly designateShopFee!: number | null;

  // 延長
  @Expose() readonly additionalFee!: number | null;
  @Expose() readonly additionalShopFee!: number | null;
  @Expose() readonly additionalCastFee!: number | null;

  // 延長
  @Expose() readonly discountFee!: number | null;
  @Expose() readonly discountShopFee!: number | null;
  @Expose() readonly discountCastFee!: number | null;

  // オプション
  @Expose() readonly optionFee!: number | null;
  @Expose() readonly optionShopFee!: number | null;
  @Expose() readonly optionCastFee!: number | null;

  // コスプレ
  @Expose() readonly cosplayFee!: number | null;
  @Expose() readonly cosplayShopFee!: number | null;
  @Expose() readonly cosplayCastFee!: number | null;

  // 交通費
  @Expose() readonly areaFee!: number | null;
  @Expose() readonly areaShopFee!: number | null;
  @Expose() readonly areaCastFee!: number | null;

  // その他
  @Expose() readonly otherFee!: number | null;
  @Expose() readonly otherShopFee!: number | null;
  @Expose() readonly otherCastFee!: number | null;
  @Expose() readonly cardFee!: number | null;
  @Expose() readonly pointFee!: number | null;

  @Expose() readonly totalFee!: number | null;
  @Expose() readonly totalShopFee!: number | null;
  @Expose() readonly totalCastFee!: number | null;

  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;

  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;

  @Type(() => StaffRes)
  @Expose()
  readonly createdBy!: StaffRes;
  @Expose() readonly createdById!: number;

  @Type(() => StaffRes)
  @Expose()
  readonly updatedBy!: StaffRes;
  @Expose() readonly updatedById!: number;
}
