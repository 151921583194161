import { Expose, Type } from "class-transformer";
import AreaRes from "../area/AreaRes";

export default class HotelRes {
  @Expose() readonly hotelId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly kana!: string;
  @Expose() readonly address!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly price!: string;
  @Expose() readonly memo!: string;
  @Type(() => AreaRes)
  @Expose()
  readonly area!: AreaRes;
  @Expose() readonly areaId!: number;
}
