import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NewReleasesIcon from "@material-ui/icons/NewReleases"; // 新着情報
import CreateIcon from "@material-ui/icons/Create"; // シフト入力
import TodayIcon from "@material-ui/icons/Today"; // スケジュール
import AlarmOnIcon from "@material-ui/icons/AlarmOn"; // 出勤確認
import HistoryIcon from "@material-ui/icons/History";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  fetchNotifications,
  readNotifications,
} from "redux/actions/notifications";
import {
  LinksWrapper,
  LinkContainer,
  IconWrapper,
  Label,
  NotificationWrapper,
  NotificationContainer,
  NotificationTitle,
  NotificationContent,
  NotificationRead,
} from "./components";
import useAsyncCallback from "hooks/useAsyncCallback";
import { Box, SvgIconTypeMap, Typography } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import DateTimeUtils from "utils/DateTimeUtils";
import { roadConditions } from "redux/actions/shifts";

type LinkType = {
  readonly href: string;
  readonly label: string;
  readonly icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
};

const links: readonly LinkType[] = [
  {
    href: "/news",
    label: "新着情報",
    icon: NewReleasesIcon,
  },
  {
    href: "/shift",
    label: "シフト入力",
    icon: CreateIcon,
  },
  {
    href: "/order",
    label: "スケジュール",
    icon: TodayIcon,
  },
  {
    href: "/orderHistory",
    label: "送迎履歴",
    icon: HistoryIcon,
  },
  {
    href: "/transfer",
    label: "送迎着車帰還",
    icon: DriveEtaIcon,
  },
  {
    href: "/shift_confirm",
    label: "出勤確認",
    icon: AlarmOnIcon,
  },
  {
    href: "/setting",
    label: "企業切り替え",
    icon: SettingsIcon,
  },
];

export const NotificationList = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const companyId = useSelector((state) => state.account.driver.companyId);
  const roadCondition = useSelector((state) => state.roadConditions);
  const { isReady, refresh } = useAsyncCallback(
    () => dispatch(fetchNotifications(companyId)),
    [companyId]
  );
  useEffect(() => {
    dispatch(roadConditions(companyId));
  }, [companyId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (
      !notifications.filter(
        (notification) => notification.notificationReads.length === 0
      ).length
    ) {
      return;
    }
    dispatch(
      readNotifications({
        companyId,
        req: notifications
          .filter((notification) => notification.notificationReads.length === 0)
          .map((notification) => notification.notificationId),
      })
    );
  }, [notifications]);

  if (!isReady) return <div>Loading...</div>;
  if (!notifications.length && !roadCondition.length)
    return (
      <Box display="flex" justifyContent="center" padding={1}>
        お知らせはありません
      </Box>
    );
  return (
    <NotificationWrapper>
      {roadCondition.map((shift) => (
        <NotificationContainer>
          <NotificationTitle>道路状況報告</NotificationTitle>
          <NotificationContent>
            <Typography>報告者：{shift.driver.name}さん</Typography>
            <Typography>{shift.roadConditionMemo}</Typography>
          </NotificationContent>
        </NotificationContainer>
      ))}
      {notifications.map((notification) => (
        <NotificationItem
          startDate={
            notification.startDate
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  notification.startDate,
                  "yyyy.MM.dd"
                )
              : ""
          }
          title={notification.title}
          content={notification.content}
          isRead={notification.notificationReads.some(
            (read) => read.notificationId === notification.notificationId
          )}
          key={notification.notificationId}
        />
      ))}
    </NotificationWrapper>
  );
};

const NotificationItem = ({
  startDate,
  title,
  content,
  isRead,
}: {
  startDate: string;
  title: string;
  content: string;
  isRead: boolean;
}) => {
  return (
    <NotificationContainer>
      <NotificationTitle>
        {startDate} {title}
      </NotificationTitle>
      <NotificationContent>{content}</NotificationContent>
      <NotificationRead>{isRead ? "既読" : "未読"}</NotificationRead>
    </NotificationContainer>
  );
};

const Top = () => {
  return (
    <>
      <LinksWrapper>
        {links.map((item) => (
          <React.Fragment key={item.href}>
            <LinkContainer to={item.href}>
              <IconWrapper>
                <item.icon fontSize="large" />
              </IconWrapper>
              <Label>{item.label}</Label>
            </LinkContainer>
          </React.Fragment>
        ))}
      </LinksWrapper>
      <NotificationList />
    </>
  );
};

export default Top;
