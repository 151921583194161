import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import { DateTimeContainer } from "../ShiftConfirm/components";
import { fetchShifts, postDriverStatus } from "redux/actions/shifts";
import DriverStatus from "../../types/enum/DriverStatus";
import useAsyncCallback from "../../hooks/useAsyncCallback";
import toastActions, { ToastType } from "../../redux/actions/toast";

const Transfer = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.driver.companyId);
  const driverId = useSelector((state) => state.account.driver.driverId);
  const changeDateTime = useSelector(
    (state) => state.account.driver.company.changeDateTime
  );
  const changeDate = changeDateTime
    ? DateTime.fromFormat(changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");

  const shifts = useSelector((state) => state.shifts);
  const [driverStatus, setDriverStatus] = useState<DriverStatus | null>(null);
  const [planReturnTime, setPlanReturnTime] = useState("--:--");
  const [message, setMessage] = useState("");
  const shift =
    shifts[
      DateTime.local()
        .minus({ hours: changeDate.hour, minutes: changeDate.minute })
        .toFormat(FORMAT_TYPE.YEAR_DAY)
    ];
  useEffect(() => {
    dispatch(
      fetchShifts({
        companyId,
        driverId,
        month: DateTimeUtils.toFormatAsLocalTimezone(new Date(), "yyyy-MM"),
      })
    );
  }, [dispatch, companyId, driverId]);

  useEffect(() => {
    if (!shift) return;
    if (shift.roadConditionMemo) {
      setMessage(shift.roadConditionMemo);
    }
    setDriverStatus(shift.driverStatus);
    if (shift.planReturnTime) {
      setPlanReturnTime(
        DateTime.fromJSDate(shift.planReturnTime).toFormat(FORMAT_TYPE.TIME)
      );
    }
  }, [shift]);

  const {
    refresh: callPostDriverStatus,
    isLoading: isPostDriverStatus,
  } = useAsyncCallback(
    () =>
      dispatch(
        postDriverStatus({
          companyId: companyId,
          req: {
            driverShiftId: shift?.driverShiftId || 0,
            driverStatus: driverStatus || null,
            roadConditionMemo: message,
            planReturnTime: DateTime.fromISO(planReturnTime).toJSDate(),
          },
        })
      ),
    [shift, driverStatus, planReturnTime, message]
  );
  const onClickSubmit = async () => {
    const res = await callPostDriverStatus();
    if (postDriverStatus.fulfilled.match(res)) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    } else if (postDriverStatus.rejected.match(res)) {
      dispatch(
        toastActions.showToast({
          text: res.error.message ?? "送信に失敗しました",
          type: ToastType.Failure,
        })
      );
    }
  };
  if (!shift) return <div>本日はシフトがありません。</div>;
  return (
    <Box padding={2} display="flex" flexDirection="column">
      <DateTimeContainer>
        {DateTime.local()
          .minus({ hours: changeDate.hour, minutes: changeDate.minute })
          .toFormat("yyyy'/'MM'/'dd (EEEE)")}
      </DateTimeContainer>
      <Divider flexItem style={{ height: 1 }} variant="middle" />
      <Box display="flex" alignItems="center" marginTop={2}>
        <Typography style={{ width: "100px" }}>現在の状態</Typography>
        <TextField
          style={{ marginLeft: "10px" }}
          select
          variant="outlined"
          value={driverStatus}
          onChange={(event: any) => setDriverStatus(event.target.value)}
        >
          {Object.entries(DriverStatus).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box display="flex" alignItems="center" marginY={2}>
        <Typography style={{ width: "100px" }}>到着予定時刻</Typography>
        <TextField
          style={{ marginLeft: "10px" }}
          type="time"
          variant="outlined"
          value={planReturnTime}
          onChange={(event) => setPlanReturnTime(event.target.value)}
        />
      </Box>
      <TextField
        label="道路状況報告"
        multiline
        fullWidth
        variant="outlined"
        rows={5}
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={onClickSubmit}
      >
        送信
      </Button>
    </Box>
  );
};

export default Transfer;
