import NotificationApi from "api/NotificationApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchNotifications = createAsyncThunk(
  "notification/fetch",
  (companyId: number) => NotificationApi.fetchNotifications(companyId)
);
export const readNotifications = createAsyncThunk(
  "notification/read",
  ({ companyId, req }: { companyId: number; req: number[] }) =>
    NotificationApi.readNotifications(companyId, req)
);
