import { Expose, Type } from "class-transformer";
import DriverRes from "../driver/DriverRes";
import ShopRes from "../shop/ShopRes";
import ShiftStatus from "types/enum/ShiftStatus";
import DriverStatus from "../../enum/DriverStatus";
import CompanyRes from "types/res/company/CompanyRes";

export default class DriverShiftRes {
  @Expose() readonly driverShiftId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly shopId!: number;
  @Expose() readonly driverId!: number;
  @Type(() => Date)
  @Expose()
  readonly planWorkStart!: Date;
  @Type(() => Date)
  @Expose()
  readonly planWorkEnd!: Date;
  @Type(() => Date)
  @Expose()
  readonly actualWorkStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly actualWorkEnd!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffEnd!: Date | null;
  @Expose() readonly status!: ShiftStatus;
  @Expose() readonly driverMemo!: string | null;
  @Expose() readonly driverStatus!: DriverStatus | null;
  @Expose() readonly roadConditionMemo!: string | null;
  @Type(() => Date)
  @Expose()
  readonly planReturnTime!: Date | null;
  @Type(() => CompanyRes)
  @Expose()
  readonly company!: CompanyRes;
  @Type(() => DriverRes)
  @Expose()
  readonly driver!: DriverRes;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
}
