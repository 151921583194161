import { fetchNotifications } from "redux/actions/notifications";
import NotificationRes from "types/res/notification/NotificationRes";
import { createSlice } from "@reduxjs/toolkit";

export type NotificationState = NotificationRes[];

const initialState = [] as NotificationState;

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (_, { payload }) => payload);
  },
});

export default notificationsSlice.reducer;
