import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import StaffRes from "../staff/StaffRes";

export default class CourseRes {
  @Expose() readonly courseId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly className!: string;
  @Expose() readonly time!: number;
  @Expose() readonly timeFee!: number;
  @Expose() readonly designateName!: string;
  @Expose() readonly welfareShopFee!: number;
  @Expose() readonly welfareDriverFee!: number;
  @Expose() readonly courseInnerShopFee!: number;
  @Expose() readonly courseInnerDriverFee!: number;
  @Expose() readonly hotelFee!: number | null;
  @Expose() readonly hotelDriverFee!: number | null;
  @Expose() readonly hotelShopFee!: number | null;
  @Expose() readonly designateFee!: number;
  @Expose() readonly designateDriverFee!: number;
  @Expose() readonly designateShopFee!: number;
  @Expose() readonly totalFee!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
  @Expose()
  readonly shopId!: number;
  @Expose() readonly companyId!: number;
  @Type(() => StaffRes)
  @Expose()
  readonly updatedBy!: StaffRes;
  @Expose()
  readonly updatedById!: number;
  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;
}
