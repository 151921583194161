import { createAsyncThunk } from "@reduxjs/toolkit";
import ShiftApi from "api/ShiftApi";
import PostShiftMessageReq from "types/req/shift/PostDriverShiftMessageReq";
import CreateDriverShiftReq from "../../types/req/shift/CreateDriverShiftReq";
import UpdateDriverShiftReq from "../../types/req/shift/UpdateDriverShiftReq";
import PostDriverShiftMessageReq from "types/req/shift/PostDriverShiftMessageReq";
import PostDriverStatusReq from "../../types/req/shift/PostDriverStatusReq";
import DeleteDriverShiftReq from "types/req/shift/DeleteDriverShiftReq";

export const fetchShifts = createAsyncThunk(
  "fetchShifts",
  ({
    companyId,
    driverId,
    month,
  }: {
    companyId: number;
    driverId: number;
    month: string;
  }) => ShiftApi.fetchShifts(companyId, driverId, month)
);

export const postShift = createAsyncThunk(
  "postShift",
  ({ companyId, req }: { companyId: number; req: CreateDriverShiftReq }) =>
    ShiftApi.postShift(companyId, req)
);

export const putShift = createAsyncThunk(
  "putShift",
  ({ companyId, req }: { companyId: number; req: UpdateDriverShiftReq }) =>
    ShiftApi.putShift(companyId, req)
);

export const deleteShift = createAsyncThunk(
  "deleteShift",
  async ({
    companyId,
    req,
  }: {
    companyId: number;
    req: DeleteDriverShiftReq;
  }) => {
    await ShiftApi.deleteShift(companyId, req);
    return req;
  }
);

export const postShiftMessage = createAsyncThunk(
  "postShiftMessage",
  ({ companyId, req }: { companyId: number; req: PostDriverShiftMessageReq }) =>
    ShiftApi.postShiftMessage(companyId, req)
);

export const approveShift = createAsyncThunk(
  "postApproveShift",
  ({
    companyId,
    driverShiftId,
  }: {
    companyId: number;
    driverShiftId: number;
  }) => ShiftApi.postApproveShift(companyId, driverShiftId)
);

export const postDriverStatus = createAsyncThunk(
  "postDriverStatus",
  ({ companyId, req }: { companyId: number; req: PostDriverStatusReq }) =>
    ShiftApi.postDriverStatus(companyId, req)
);

export const roadConditions = createAsyncThunk(
  "roadConditions",
  (companyId: number) => ShiftApi.roadConditions(companyId)
);
