import React, { useState, useEffect } from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import { DateTime } from "luxon";
import { useSelector, useDispatch } from "react-redux";
import useAsyncCallback from "hooks/useAsyncCallback";
import { fetchOrders } from "redux/actions/orders";
import { AppointmentContent, AppointmentTooltipContent } from "./components";

const Order = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const driver = useSelector((state) => state.account.driver);
  const [currentDate, setCurrentDate] = useState(
    DateTime.local().toFormat("yyyy-MM-dd")
  );
  const currentMonth = DateTime.fromFormat(currentDate, "yyyy-MM-dd").toFormat(
    "yyyy-MM"
  );
  const { isReady, refresh } = useAsyncCallback(
    () =>
      dispatch(
        fetchOrders({
          driverId: driver.driverId,
          companyId: driver.companyId,
          startDate: DateTime.fromISO(currentDate)
            .startOf("month")
            .toFormat("yyyy-MM-dd"),
          endDate: DateTime.fromISO(currentDate)
            .endOf("month")
            .toFormat("yyyy-MM-dd"),
        })
      ),
    [currentMonth]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const onCurrentDateChange = (currentDate: Date) => {
    setCurrentDate(DateTime.fromJSDate(currentDate).toFormat("yyyy-MM-dd"));
  };

  if (!isReady) return <div>Loading</div>;

  return (
    <div>
      <Scheduler data={orders} locale="ja-JP">
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={onCurrentDateChange}
        />
        <DayView startDayHour={0} endDayHour={24} />
        <Toolbar />
        <DateNavigator />
        <TodayButton />
        <Appointments appointmentContentComponent={AppointmentContent} />
        <AppointmentTooltip contentComponent={AppointmentTooltipContent} />
      </Scheduler>
    </div>
  );
};

export default Order;
