import { createSlice } from "@reduxjs/toolkit";
import { roadConditions } from "redux/actions/shifts";
import DriverShiftRes from "types/res/shift/ShiftRes";

export type RoadConditionState = DriverShiftRes[];

const initialState = [] as RoadConditionState;
const roadConditionsSlice = createSlice({
  name: "roadConditions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(roadConditions.fulfilled, (_, { payload }) => payload);
  },
});

export default roadConditionsSlice.reducer;
