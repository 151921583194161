import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Main from "layouts/Main";
import Page404 from "pages/Page404";
import Top, { NotificationList } from "pages/Top";
import Shift from "pages/Shift";
import Signin from "../pages/Signin";
import Order from "pages/Order";
import Transfer from "pages/Transfer";
import ShiftConfirm from "pages/ShiftConfirm";
import { useSelector } from "react-redux";
import ResetPasswordSend from "pages/ResetPasswordSend";
import ResetPassword from "pages/ResetPassword";
import OrderHistory from "pages/OrderHistory";
import Setting from "pages/Setting";

type RouteType = {
  path: string;
  component: React.FC<any>;
  exact?: boolean;
};

type Routes = {
  [key: string]: RouteType;
};

const mainRoutes: Routes = {
  top: {
    path: "/",
    component: Top,
    exact: true,
  },
  news: {
    path: "/news",
    component: NotificationList,
  },
  shift: {
    path: "/shift",
    component: Shift,
  },
  orderHistory: {
    path: "/orderHistory",
    component: OrderHistory,
  },
  order: {
    path: "/order",
    component: Order,
  },
  inout: {
    path: "/transfer",
    component: Transfer,
  },
  shiftConfirm: {
    path: "/shift_confirm",
    component: ShiftConfirm,
  },
  setting: {
    path: "/setting",
    component: Setting,
  },
};

const unauthorizedRoutes: Routes = {
  ResetPasswordSend: {
    path: "/resetPassword/send",
    component: ResetPasswordSend,
  },
  ResetPassword: {
    path: "/resetPassword",
    component: ResetPassword,
  },
  signin: {
    path: "/login",
    component: Signin,
  },
};
const configureRoutes = (
  Layout: React.FC,
  routes: Routes,
  redirect?: string,
  authorized = true
) => {
  const components = Object.values(routes).map(
    ({ component: Component, path, exact = false }, index) => (
      <Route
        key={index}
        path={path}
        exact={exact}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

  if (redirect) {
    components.push(<Redirect key="redirect" to={redirect} />);
  }

  return components;
};

const Auth: React.FC = ({ children }) => {
  const isLoggedin = useSelector((state) => Boolean(state.account.accessToken));

  return (
    <Switch>
      {isLoggedin
        ? children
        : configureRoutes(Main, unauthorizedRoutes, "/login", false)}{" "}
    </Switch>
  );
};

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Auth>{configureRoutes(Main, mainRoutes, "/")}</Auth>
        <Route render={() => <Page404 />} />
      </Switch>
    </Router>
  );
};

export default Routes;
