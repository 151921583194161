import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import CarRes from "../car/CarRes";
import CompanyRes from "types/res/company/CompanyRes";

export default class DriverRes {
  @Expose() readonly driverId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly name!: string;
  @Expose() readonly address!: string;
  @Type(() => CompanyRes)
  @Expose()
  readonly company!: CompanyRes;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
  @Type(() => CarRes)
  @Expose()
  readonly cars!: CarRes[];
}
