import ShiftRes from "types/res/shift/ShiftRes";
import RequestUtils from "../utils/RequestUtils";
import CreateDriverShiftReq from "../types/req/shift/CreateDriverShiftReq";
import UpdateDriverShiftReq from "../types/req/shift/UpdateDriverShiftReq";
import PostDriverShiftMessageReq from "types/req/shift/PostDriverShiftMessageReq";
import PostDriverStatusReq from "../types/req/shift/PostDriverStatusReq";
import DeleteDriverShiftReq from "types/req/shift/DeleteDriverShiftReq";

export default class ShiftApi {
  static async fetchShifts(
    companyId: number,
    driverId: number,
    month: string
  ): Promise<ShiftRes[]> {
    return await RequestUtils.getArray(
      ShiftRes,
      `/shift/findAll/${companyId}/${driverId}/${month}`
    );
  }

  static async postShift(companyId: number, req: CreateDriverShiftReq) {
    return await RequestUtils.post(ShiftRes, `/shift/create/${companyId}`, req);
  }

  static async putShift(companyId: number, req: UpdateDriverShiftReq) {
    return await RequestUtils.post(ShiftRes, `/shift/update/${companyId}`, req);
  }

  static async deleteShift(companyId: number, req: DeleteDriverShiftReq) {
    return await RequestUtils.postVoid(`/shift/delete/${companyId}`, req);
  }

  static async postShiftMessage(
    companyId: number,
    req: PostDriverShiftMessageReq
  ) {
    return await RequestUtils.post(
      ShiftRes,
      `/shift/message/${companyId}`,
      req
    );
  }

  static async postApproveShift(companyId: number, driverShiftId: number) {
    return await RequestUtils.post(
      ShiftRes,
      `/shift/approve/${companyId}/${driverShiftId}`,
      {}
    );
  }

  static async postDriverStatus(companyId: number, req: PostDriverStatusReq) {
    return await RequestUtils.post(
      ShiftRes,
      `/shift/driverStatus/${companyId}`,
      req
    );
  }

  static async roadConditions(companyId: number) {
    return await RequestUtils.getArray(
      ShiftRes,
      `/shift/roadConditions/${companyId}`
    );
  }
}
