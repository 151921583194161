import React from "react";
import {
  Appointments,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import DateTimeUtils from "utils/DateTimeUtils";
import styled from "styled-components";
import { Order as OrderType } from "redux/reducers/orders";
import { Typography } from "@material-ui/core";

const Bold = styled.span`
  font-weight: bold;
`;

const ScheduleItem = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  return (
    <div>
      <Bold>{title}:</Bold> {content}
    </div>
  );
};

const MainItems = ({ data }: { data: OrderType }) => {
  return (
    <>
      <Typography>{data.returnFlag ? "【迎え】" : "【送り】"}</Typography>
      <ScheduleItem title="顧客名" content={data?.guest.name || "未設定"} />
      <ScheduleItem
        title="出発"
        content={
          data.departureTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.departureTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定IN"
        content={
          data.planInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planInTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定OUT"
        content={
          data.planOutTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planOutTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem title="店舗名" content={data.shop.name || ""} />
      <ScheduleItem title="コース" content={data.course.name || ""} />
      <ScheduleItem title="住所1" content={`${data.orderAddress || ""}`} />
      <ScheduleItem title="住所2" content={`${data.orderAddress2 || ""}`} />
      <ScheduleItem title="総額" content={`${data.totalFee || 0}円`} />
    </>
  );
};

export const AppointmentContent = ({ children, data, ...restProps }: any) => {
  return (
    <Appointments.AppointmentContent
      data={data}
      {...restProps}
      style={{ fontSize: 14, backgroundColor: data.backgroundColor }}
    >
      <div>
        <MainItems data={data} />
      </div>
    </Appointments.AppointmentContent>
  );
};

export const AppointmentTooltipContent = ({
  appointmentData,
  formatDate,
  children,
  ...restProps
}: any) => {
  const { options, cosplay, totalCastFee, memo } = appointmentData;
  return (
    <AppointmentTooltip.Content
      appointmentData={appointmentData}
      {...restProps}
      formatDate={formatDate}
    >
      <MainItems data={appointmentData as OrderType} />
      <ScheduleItem title="バック" content={`${totalCastFee}円`} />
      <ScheduleItem
        title="オプション"
        content={options.map((option: any) => option.name || "").join("/")}
      />
      <ScheduleItem title="コスプレ" content={cosplay?.name || ""} />
      <ScheduleItem title="備考" content={memo} />
    </AppointmentTooltip.Content>
  );
};
