import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";

export default class AreaRes {
  @Expose() readonly areaId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly totalFee!: number;
  @Expose() readonly shopFee!: number;
  @Expose() readonly driverFee!: number;
  @Expose() readonly time!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
