import RequestUtils from "../utils/RequestUtils";
import NotificationRes from "../types/res/notification/NotificationRes";

export default class NotificationApi {
  static async fetchNotifications(companyId: number) {
    return await RequestUtils.getArray(
      NotificationRes,
      `/notification/findAll/${companyId}`
    );
  }

  static async readNotifications(companyId: number, req: number[]) {
    return await RequestUtils.postVoid(
      `/notification/isRead/${companyId}`,
      req
    );
  }
}
