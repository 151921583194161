import OrderRes from "types/res/order/OrderRes";
import RequestUtils from "../utils/RequestUtils";

export default class OrderApi {
  static async fetchOrders(
    companyId: number,
    driverId: number,
    startDate: string,
    endDate: string
  ) {
    return await RequestUtils.getArray(
      OrderRes,
      `/order/findAll/${companyId}/${driverId}/${startDate}/${endDate}`
    );
  }
  static async putIn(companyId: number, orderId: number) {
    return await RequestUtils.post(
      OrderRes,
      `/order/putIn/${companyId}/${orderId}`,
      {}
    );
  }
  static async putOut(companyId: number, orderId: number) {
    return await RequestUtils.post(
      OrderRes,
      `/order/putOut/${companyId}/${orderId}`,
      {}
    );
  }
}
