import { Expose, Type } from "class-transformer";
import DriverRes from "../driver/DriverRes";

export default class DriverNameRes {
  @Expose() readonly driverNameId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly driverId!: number;
  @Expose() readonly name!: string;
  @Type(() => DriverRes)
  @Expose()
  readonly driver!: DriverRes;
}
