import DriverApi from "api/DriverApi";
import LoginDriverReq from "types/req/driver/LoginDriverReq";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DriverRes from "types/res/driver/DriverRes";

export const signin = createAsyncThunk(
  "account/signin",
  async ({ email, password }: { email: string; password: string }) => {
    const req = new LoginDriverReq({ email, password });
    const result = await DriverApi.login(req);
    return result;
  }
);

export const logout = createAsyncThunk("account/logout", async () =>
  DriverApi.logout()
);
export const changeAccount = createAsyncThunk(
  "account/changeAccount",
  async ({ driver }: { driver: DriverRes }) => {
    return driver;
  }
);
