import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchOrders } from "redux/actions/orders";
import useAsyncCallback from "hooks/useAsyncCallback";
import { DateTime, Interval } from "luxon";
import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import { FORMAT_TYPE } from "utils/DateTimeUtils";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const driver = useSelector((state) => state.account.driver);
  // const [currentDate, setCurrentDate] = useState(
  //   DateTime.local().toFormat("yyyy-MM-dd")
  // );
  const [currentMonth, setCurrentMonth] = useState(
    DateTime.local().toFormat("yyyy-MM")
  );
  const interval = Interval.fromDateTimes(
    DateTime.fromFormat("2000-01-01", FORMAT_TYPE.YEAR_DAY),
    DateTime.local().endOf("month")
  );

  function* months(interval: Interval) {
    let cursor = interval.start.startOf("month");
    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus({ months: 1 });
    }
  }
  const monthArray = Array.from(months(interval)).sort((a, b) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  });

  const { isReady, refresh } = useAsyncCallback(
    () =>
      dispatch(
        fetchOrders({
          driverId: driver.driverId,
          companyId: driver.companyId,
          startDate: DateTime.fromFormat(currentMonth, "yyyy-MM")
            .startOf("month")
            .toFormat("yyyy-MM-dd"),
          endDate: DateTime.fromFormat(currentMonth, "yyyy-MM")
            .endOf("month")
            .toFormat("yyyy-MM-dd"),
        })
      ),
    [currentMonth]
  );
  useEffect(() => {
    refresh();
  }, [refresh]);
  if (!isReady) return <div>Loading</div>;
  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        marginTop={1}
        paddingX={3}
        justifyContent="space-between"
      >
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          送迎履歴
        </Typography>
        <TextField
          select
          value={currentMonth}
          onChange={(event) => setCurrentMonth(event.target.value)}
        >
          {monthArray.map((month) => (
            <MenuItem
              key={month.toFormat("yyyy-MM")}
              value={month.toFormat("yyyy-MM")}
            >
              {month.toFormat("yyyy-MM")}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {orders.length === 0 ? (
        <Box paddingX={3} marginTop={1}>
          <Typography>送迎履歴はありません</Typography>
        </Box>
      ) : (
        <></>
      )}
      {[
        ...new Map(orders.map((order) => [order["orderId"], order])).values(),
      ].map((order) => (
        <Box
          display="flex"
          flexDirection="column"
          marginTop={1}
          borderTop={1}
          paddingTop={1}
          paddingX={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              顧客
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.guest?.name || ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              キャスト
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.castName?.name || ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              出発
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.departureTime
                ? DateTime.fromJSDate(order.departureTime).toFormat(
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              予定IN
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.planInTime
                ? DateTime.fromJSDate(order.planInTime).toFormat(
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              予定OUT
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.planOutTime
                ? DateTime.fromJSDate(order.planOutTime).toFormat(
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : ""}
            </Typography>{" "}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              店舗名
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.shop?.name || ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              コース
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.course?.name || ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              住所1
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.orderAddress || ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              住所2
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.orderAddress2 || ""}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              総額
            </Typography>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {order?.totalFee || 0}円
            </Typography>
          </Box>
        </Box>
      ))}
    </div>
  );
};

export default OrderHistory;
