import { createSlice } from "@reduxjs/toolkit";

import { fetchOrders } from "redux/actions/orders";
import OrderRes from "types/res/order/OrderRes";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { DateTime } from "luxon";

export type Order = OrderRes & AppointmentModel;
export type OrderState = Order[];

const initialState = [] as OrderState;
const configureOrders = (raw: OrderRes[], driverId: number): OrderState => {
  const returnPlan = raw
    .filter((orderRes) => orderRes.returnDriverId === driverId)
    .map((orderRes) => ({
      ...orderRes,
      startDate:
        orderRes.returnDuration && orderRes.planOutTime
          ? DateTime.fromJSDate(orderRes.planOutTime)
              .minus({ minutes: orderRes.returnDuration })
              .toFormat("yyyy-MM-dd'T'HH:mm")
          : "",
      endDate:
        orderRes.returnDuration && orderRes.planOutTime
          ? DateTime.fromJSDate(orderRes.planOutTime).toFormat(
              "yyyy-MM-dd'T'HH:mm"
            )
          : "",
      returnFlag: true,
      backgroundColor: "#4ec14e",
    }));
  const outwardPlan = raw
    .filter((orderRes) => orderRes.outwardDriverId === driverId)
    .map((orderRes) => ({
      ...orderRes,
      startDate: orderRes.departureTime
        ? DateTime.fromJSDate(orderRes.departureTime).toFormat(
            "yyyy-MM-dd'T'HH:mm"
          )
        : "",
      endDate: orderRes.planInTime
        ? DateTime.fromJSDate(orderRes.planInTime).toFormat(
            "yyyy-MM-dd'T'HH:mm"
          )
        : "",
      returnFlag: false,
      backgroundColor: "#64b5f6",
    }));
  return [...returnPlan, ...outwardPlan];
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.fulfilled, (_, { payload }) =>
      configureOrders(payload.orders, payload.driverId)
    );
  },
});

export default ordersSlice.reducer;
