import RequestUtils from "../utils/RequestUtils";
import OrderRes from "../types/res/order/OrderRes";

export default class PayoffApi {
  static async fetchPayoffs(
    companyId: number,
    driverId: number,
    startDate: string,
    endDate: string
  ) {
    return await RequestUtils.getArray(
      OrderRes,
      `/order/payoff/${companyId}/${driverId}/${startDate}/${endDate}`
    );
  }
}
