import { createAsyncThunk } from "@reduxjs/toolkit";
import OrderApi from "api/OrderApi";

export const fetchOrders = createAsyncThunk(
  "fetchOrders",
  async ({
    companyId,
    driverId,
    startDate,
    endDate,
  }: {
    companyId: number;
    driverId: number;
    startDate: string;
    endDate: string;
  }) => ({
    orders: await OrderApi.fetchOrders(companyId, driverId, startDate, endDate),
    driverId,
  })
);
